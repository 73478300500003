.com_sketchslider_pro {
	.sketch_content.item, .sketch_content.category{
		label{
			margin-bottom: 24px;
			font-size: 15px;
		}
		form#sketchform-form{
			padding-top: 24px;
		}
		input[type="text"], div.chzn-container,.input-prepend.input-append{
			margin-bottom: 12px;
		}
		input[type="text"]{
			height: 30px;
		}
		.input-prepend.input-append{
			.media-preview{
				height: 30px;
				line-height: 17px;
			}
			a.modal.btn {
				height: 30px;
				line-height: 17px;
			}
			a.btn.hasTooltip {
				height: 30px;
				line-height: 17px;
			}
		}
		.chzn-container {
			max-width: 206px!important;
		}
		.minicolors {
			min-width: 108px;
		}
		span.minicolors-panel{
			box-sizing: content-box !important;
			*{
				box-sizing: content-box !important;
			}
		}
	}
}

