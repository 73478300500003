header.header {
	display: none;
}

.subhead-collapse.collapse {
	margin-bottom: 0;
}

.subhead.subhead-fixed, div.subhead {
	background: #5E5E5E;
	border-bottom: 0;
	box-shadow: none;
}

.com_sketchslider_pro {

	h1.page-title {
		span.icon-generic {
			&:before {
				content: '';
				background-image: url(../images/sketchdot.svg);
				background-repeat: no-repeat;
			}
		}
	}
	section#content {
		background-color: #ddd;
	}
	.container-fluid.container-main {
		padding-left: 0;
		padding-right: 0;
		* {
			box-sizing: border-box;
		}
	}
	.sketch_filterbar, .sketch_headlines {
		width: 230px;
	}
	option.category_option.level_1 {
		background-color: #eee;
		font-size: 12px;
	}
	.sketch_content {
		display: block;
		background-color: #adadad;
		margin-left: 263px;
		padding-left: 24px;
		padding-right: 24px;
		min-height: 91vh;
		&.listing {
			form#adminForm {
				padding-top: 24px;
			}
			.sketch_items {
				.sketch_item {
					a.wrap {
						display: block;
						border: 1px solid #ddd;
						padding: 12px;
						background-color: #555;
						transition: .3s all ease 0s;
						position: relative;
						margin-bottom: 24px;
						h3 {
							text-decoration: none;
							color: #fff;
						}
						&:hover {
							background-color: #777;
							text-decoration: none;
						}
					}
					.id {
						position: absolute;
						font-weight: bold;
						margin-left: 5px;
						font-size: 22px;
						color: #fff;
						text-shadow: 0 0 4px #000;
					}
					.state {
						position: absolute;
						top: 0;
						right: 0;
						background-color: #fff;
						border-radius: 100%;
						width: 20px;
						height: 20px;
						padding-left: 2px;
						text-align: center;
						border: 1px solid #000;
					}
					.check {
						position: absolute;
						top: 0px;
						left: 5px;
					}
					.title {
						text-align: center;
						color: #fff;
						font-weight: bold;
						font-size: 18px;
						border-bottom: 1px solid #fff;
					}
					.image {
						margin-bottom: 12px;
					}
					.category {

						color: $light;
						font-size: 16px;
						font-weight: lighter;

					}
				}
			}
		}
		&.categories {
			padding-top: 24px;
			.sketch_item {
				background: $dark;
				border: 1px solid $light;
				float: left;
				width: 100%;
				clear: both;
				margin-bottom: 2px;
				a > div{
					float: left;
					display: block;
				}
				.categorytitle{
					display: block;
					float: left;
					h3{
						color: $light;
						margin-top: 2px;
						margin-bottom: 2px;
					}
				}
				.statesbox{
					padding-top: 2px;
					float: right;
				}
				.inputbox{
					padding-left: 6px;
					padding-right: 6px;
				}
			}
		}
	}
}

