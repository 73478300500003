// base font size - adjust it to <p> size
$base-font-size: 16px;
$base-font-family: 'Open Sans', sans-serif;

/* Colors */
$base: #333;
$link: #bd1e56;
$link_hover: lighten(#bd1e56,10%);
$bgbox: #FCFCFC;
$main: #bd1e56;
$green: #ECC14E;
$dark: #444444;
$light: #fff;
$border: #acacac;
$bg: #cbcbcb;
$orange: #ECC14E;
$box-shadow: 5px 5px 16px 6px #000;
$pink: #bd1e56;


	// Media Queries
$small-screen: 480px !default;
$medium-screen: 768px !default;
$large-screen: 1024px !default;
$xlarge-screen: 1200px !default;

$screen: "only screen" !default;

$small: "only screen and (min-width: #{$small-screen})" !default;
$small-max: "only screen and (max-width: #{$small-screen+1})";
$medium: "only screen and (min-width:#{$medium-screen})" !default;
$medium-max: "only screen and (max-width: #{$medium-screen+1})";
$large: "only screen and (min-width:#{$large-screen})" !default;
$large-max: "only screen and (max-width: #{$large-screen+1})";
$xlarge: "only screen and (min-width:#{$xlarge-screen})" !default;
$xlarge-max: "only screen and (max-width: #{$xlarge-screen+1})";

$landscape: "only screen and (orientation: landscape)" !default;
$portrait: "only screen and (orientation: portrait)" !default;

// Zeug fürs Grid
@mixin clearfix() {
	*zoom:1;
	&:before, &:after { content: " "; display: table; }
	&:after { clear: both; }
}

@mixin inline-list {
	margin: 0 auto 0 auto;
	margin-left: -1em;
	margin-right: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;

	& > li {
		list-style: none;
		float: left;
		margin-left: 1em;
		display: block;
		&>* { display: block; }
	}
}
