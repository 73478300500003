.header{
	padding-top: 36px !important;
}
form#adminForm {
	height: 100%;
}
.com_sketchslider_pro {
	&.admin{
		div#toolbar-options {
			float: right !important;
		}
		input#filter_search{
			line-height: 18px;
			min-height: 18px;
			max-width: 100%;
		}
		.row{
			max-width: 100%;
		}
		.premium-opens{
		}
	}
	h1.page-title{
		span.icon-generic{
			background-color: transparent !important;
			border-right: 0 !important;
			&:before{
				content:'';
				background-image:url(../images/sketchdot.svg);
				width: 24px;
				height: 24px;
				top: 2px;
				position: relative;
				display: block;
				background-size: contain;
				filter: invert(100%);
			}
		}
	}

	ul.chzn-results {
		float: left;
		width: 100%;
	}
	.sketchslider_pro_admin {
		.row.actions {
			padding: 9px !important;
			background-color: #ddd;
		}
		.csvexport{
			a{
				background-color: rgb(92, 92, 92);
				color: #fff;
				padding: 5px;
				border: 1px solid rgb(171, 171, 171);
				&:hover{
					color: #333;
					background-color: #e6e6e6;
				}
			}
		}
		a{
			transition: .5s all ease 0s;
		}
		button {
			background: #5C5C5C;
			color: #fff;
			text-shadow: none;
			box-shadow: none;
			border: 1px solid #ABABAB;
			transition: .5s all ease 0s;
			line-height: 24px;
			float: left;
			margin-left: 12px;
			&:hover {
				color: #333;
				background-color: #e6e6e6;
			}
		}
		.uneditable-input, input[type="text"], textarea {
			width: 220px;
		}
		button#jform_created_on_img {
			margin-left: 0;
			min-height: 34px;
		}
		.roomtable{
			tr{
				border-bottom: 1px solid #333;
			}
		}
		label {
			min-width: 0;
			display: inline-block;
			line-height: 24px;
			padding-right: 5px;
			float: left;
		}
		input[type="time"] {
			min-height: 35px;
			font-size: 17px;
		}
		input[type="checkbox"] {
			margin: 0 4px;
			line-height: normal;
		}
		.radio input[type="radio"] {
			margin-left: 0;
		}
		input[type="text"] {
			border: 1px solid #333;
			float: left;
			min-height: 28px;
		}
		select{
			float: left;
			line-height: 24px;
			margin-left: 12px;
		}
		.chzn-container, .chzn-drop {
			margin-bottom: 12px;
		}
		button#jform_publish_start_img,button#jform_created_on_img, button#jform_publish_end_img{
			max-height: 28px;
			line-height: 21px;
		}
		select#limit {
			line-height: 24px;
			padding: 3px;
		}
		table.mceLayout {
			margin-bottom: 24px;
		}
		#field.tab-pane .row {
			border-bottom: 2px solid #f7f7f7;
			margin-bottom: 6px;
			padding-bottom: 0;
		}
		label#jform_f_premium_description-lbl,label#jform_f_premium_intro-lbl,label#jform_f_premium-lbl {
			font-size: 16px;
			font-weight: bold;
		}
		fieldset#jform_f_premium {
			float: left;
			margin-bottom: 24px;
			background-color: rgb(203, 203, 203);
			padding: 12px;
			border-radius: 5px;
			input[type="radio"]{
				margin-left: 0;
			}
		}
		input.hasTipImgpath{
			width: 100%;
			@media#{$medium}{
				width: 250px
			}
		}
		#pictures .row {
			border-bottom: 1px solid #ddd;
			margin-bottom: 12px;
			padding-bottom: 4px;
		}
		table{
			width: 100%;
			thead{
				th{
					background: #5c5c5c;
					color: #fff;
					a{
						color: #fff;
						&:hover{
							color: $link;
						}
					}
				}
			}
			&.sketchslider_pro_listing{
				thead{
					th{
						&:first-child{
							max-width: 25px;
						}
					}
				}
				tbody{
					tr{
						&:nth-child(even){

						}
						&:nth-child(odd){

					}
					}
				}
			}
		}
	}
	.admin_ratings{
		th{
			font-size: 12px;
			padding: 0 5px;
		}
		tr{
			border-bottom: 1px solid $base;
			td{
				vertical-align: top;
				padding: 3px;
			}
		}
	}
	.tip {
		overflow: hidden;
	}
}
span.add-on.pop-helper.field-media-preview {
	 min-height: 30px;
 }
a.btn.add-on.button-select {
	min-height: 30px;
}