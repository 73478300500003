.com_sketchslider_pro {
	h1.page-title{
		padding-left: 40px;
	}
	ul.nav-tabs {
		li {
			a {
				background-color: #ddd;
				margin-right: 6px;
			}
			&.active{
				a{
					background-color: #fff;
				}
			}
		}
	}
	.sketch_content {
		margin-left: 0;
		transition: .4s margin-left ease 0s;
		overflow: hidden;
		padding-bottom: 90px;
		&.open {
			margin-left: 263px;
		}
	}
	.btn-wrapper {
		float: right;
	}
	.sketch_menu {
		display: block;
		padding-top: 24px;
		float: left;
		@media#{$medium}{
			height: 100vh;
		}
		width: 262px;
		padding-left: 24px;
		padding-right: 24px;
		background-color: #ddd;
		position: relative;
		margin-left: -263px;
		transition: .4s margin-left ease 0s;
		&.open {
			margin-left: 0;
		}
		ul {
			list-style: none;
			margin-left: 0;
			padding-left: 0;
			li {
				border-bottom: 1px solid #737373;
				span {
					display: block;
					padding: 7px 10px;
					background-color: #333;
					color: #a9a9a9;
				}
				a {
					display: block;
					background-color: #333;
					padding: 7px 10px;
					color: #fff;
					transition: .3s all ease 0s;
					&:hover {
						background-color: #444;
						text-decoration: none;
					}
				}
				&.active {
					a {
						background-color: #fff;
						color: #333;
					}
				}
				&.parent {
					background-color: #333;
					color: #ddd;
					ul {
						li {
							border-bottom: 0;
							a {
								padding-left: 24px;
							}
						}
					}
					a {
					}
				}
				&.categories {
					a {
					}
				}
			}
		}
	}
	.sketch_filterbar {
		background-color: #333;
		padding: 12px;
		margin-bottom: 12px;
		h2, label {
			color: #fff;
		}
		input#filter_search {
			line-height: 30px;
			min-height: 30px;
			max-width: 100%;
		}
		.sketch_filterbar_search {
			margin-bottom: 12px;
		}
		select {
			max-width: 100%;
		}
		.sketch_filterbar_search {
			button {
				background-color: #ddd;
				color: #333;
				border: 1px solid #333;
				border-radius: 3px;
				transition: .3s all ease 0s;
				padding: 4px 6px;
				&:hover {
					background-color: #fff;
				}
			}
			button[type=submit] {
				min-width: 86px;
				margin-right: 7px;
			}
		}
	}
	.sketch_headlines {
		background-color: #333;
		padding: 12px;
		h2, label {
			color: #fff;
		}
		a{
			color: #fff;
			&:hover{
				color: #ddd;
				text-decoration: none;
			}
		}
	}
}

span.toggler.icon-menu-3 {
	font-size: 18px;
	color: #fff;
	position: absolute;
	z-index: 9999;
	top: 49px;
	left: 23px;
	cursor: pointer;
}